import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import mtn from "assets/images/logos/mtn.png";
import telkom from "assets/images/logos/telkom.png";
import vodacom from "assets/images/logos/vodacom.png";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";
import Button from "@mui/material/Button";
import CampaignProvider from "../../../../providers/CampaignProvider";
import UsersProvider from "../../../../providers/UsersProvider";
import TeamMembersProvider from "../../../../providers/TeamMembersProvider";
import BeatLoader from "react-spinners/BeatLoader";
import Grid from "@mui/material/Grid";


const today = new Date();
const time = today.getDate() + ":" + today.getMonth() + ":" + today.getFullYear();

function CampaignsCard({rolePageException, campaigns, disableEscalations, alert, getApprovals, setDisplayHelpText}) {
  const [userCampaigns, setUserCampaigns] = useState(campaigns || []);
  const [loading, setLoading] = useState(false);
  const [escalateProcess, setEscalateProcess] = useState(false);

  const submitEscalation = async () => {
    setLoading(true);
    let userObj = await UsersProvider.getUserHierarchyByEmployeeNumber({employeeNumber: localStorage.getItem('EmployeeNumber')}); 
    const userEmployeeNumber = localStorage.getItem("EmployeeNumber");

    let approvers = [];

    if(userObj?.Manager?.EmployeeNumber){
      approvers.push(userObj.Manager.EmployeeNumber)
    }
    if(userObj?.Manager?.Manager?.EmployeeNumber){
      approvers.push(userObj.Manager.Manager.EmployeeNumber);
    }

    if(approvers.length == 0){
      let user = await UsersProvider.getUsersSearch({employeeNumber: localStorage.getItem('EmployeeNumber')}); 
      if(user && user[0]?.ManagerUsers){
        approvers.push(user[0].ManagerUsers[0].EmployeeNumber);
      }
    }

    let requestPayload = {
        RequestType: "CAMPAIGN ESCALATION",
        RequestDescription: "portal-request",
        CreatedBy: localStorage.getItem("UserName") || null,
        RequesterUserEmployeeNumber: userEmployeeNumber ,
        ApproverUserEmployeeNumbers: approvers,
        ManagerUserEmployeeNumber: userObj.Manager.EmployeeNumber
    }
        
    
    
    let res = await TeamMembersProvider.createEscalationRequest({ data: requestPayload });
    if(res && res.status === 'success'){
      await getApprovals();
      setDisplayHelpText(false);
      alert({
        icon:'success',
        title: 'Escalation Successfully Submitted.',
      });
      setEscalateProcess(false);
    }else{
      alert({
        icon:'error',
        title: 'Escalation Submission Failed.',
      });
    }
    setLoading(false);
  }
 
  return (
    <Card sx={{ height: "100%" }}>
      <SuiBox display="flex" alignItems="center" p={2}>
        <SuiBox>
          <SuiTypography mb={0.5} variant="h6" fontWeight="medium">
            Campaign
          </SuiTypography>
          <SuiBox
            display="flex"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            {localStorage.getItem("roleName") === "Agent" && <SuiAvatar
              src={""}
              alt="something here"
              variant="rounded"
              shadow="md"
            />}
            <SuiTypography color="secondary" variant="h6">
              {campaigns && campaigns.length > 0 ? (
                localStorage.getItem("roleName") === "Agent"  ? (
                  `${campaigns[0].CampaignLeadSet} ( ${campaigns[0].Name} )`
                ) : (
                  <ul style={{padding: '1rem 2rem'}}>
                    {campaigns.map((campaign) => (
                      <li>
                        <SuiTypography px={1} color="gray" variant="h6">
                          {campaign.Code} ( {campaign.Name} )
                        </SuiTypography>
                      </li>
                    ))}
                  </ul>
                )
              ) : (
                <p>No Campaigns Found.</p>
              )}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        {rolePageException && <SuiBox ml="auto">
          {escalateProcess ? (
            <Grid flexDirection={'column'} justifyContent={"end"} display={"flex"} gap={2} container>
              <SuiTypography color="secondary" variant="caption">
                Are you sure you want to escalate this request?
              </SuiTypography>

              <SuiBox justifyContent='center' display={"flex"} gap={2} container>
                <SuiButton
                  disabled={loading || disableEscalations}
                  onClick={() => {
                    submitEscalation();
                  }}
                  sx={{ padding: ".5rem 3rem" }}
                  color="success"
                  variant="gradient"
                >
                  Yes
                  {loading && <>
                    &nbsp;
                    <BeatLoader color={'#D0021B'} loading={loading} size={6} />
                  </>}
                  
                </SuiButton>
                <SuiButton
                  onClick={() => {
                    setEscalateProcess(false);
                  }}
                  sx={{ padding: loading ? ".5rem 0" : ".5rem 3rem" }}
                  color="error"
                  variant="gradient"
                >
                  No
                </SuiButton>
              </SuiBox>
              
              
            </Grid>
          ) : (
            <SuiButton
              disabled={disableEscalations}
              onClick={() => {
                setEscalateProcess(true);
              }}
              sx={{ padding: ".5rem 3rem" }}
              color="info"
              variant="gradient"
            >
              Escalate
            </SuiButton>
          )}
        </SuiBox>}
      </SuiBox>
    </Card>
  );
}

CampaignsCard.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CampaignsCard;
