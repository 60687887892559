import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DefaultComponentLoader from "components/Loaders/DefaultComponentLoader";
import requesterErrorMessages from '../../static/messages/errorMessages.json';
import { setOpenRequestor, setRequestor, useSoftUIController} from "context";
import requesterConfig from '../../static/configs/requesterConfig.json';
import TeamMembersProvider from "../../providers/TeamMembersProvider";
import ApprovalProvider from "../../providers/ApprovalProvider";
import RequestorRoot from "examples/Requestor/RequestorRoot";
import { FormProvider, useForm } from 'react-hook-form';
import { FaRegCheckCircle } from "react-icons/fa";
import DatePicker from "react-datepicker";
import useAlert from "../../common/useAlert";
import { isEmpty } from "lodash";
import { GetRequestTypeAccess } from "./requestorUtils"

//Providers
import DisplayBlockRenderer from '../../components/ContentBlocks/index';
import MovementReasonProvider from '../../providers/MovementReasonProvider';

// UI components
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import Select from "react-select";
import SuiInput from "components/SuiInput";
import { CampaignEscalation } from "./views/CampaignAndManagerEscalationApproval";
import { UsersMovementEscalation } from "./views/UsersMovementRequest";
import { CampaignCreation } from "./views/CampaignCreation";
import { UserCampaignUpdate } from "./views/UserCampaignUpdate";
import { UsersCreation } from "./views/UsersCreation";
import { UsersMovement } from "./views/UsersMovementApproval";
import { UserToRemove } from "./views/UserToRemove";

function Requestor() {
  const [controller, dispatch] = useSoftUIController();
  const { requestor, openRequestor, fixedNavbar } = controller;
  const [currentModal, setCurrentModal] = useState({});
  // const [apiRequestObj, setApiRequestObj] = useState(null);
  const apiRequestObj = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState({
    show: false,
    message: ''
  });
  
  //VARIABLES FOR CONFIG
  const config = requesterConfig;
  const methods = useForm();
  const { alert } = useAlert();
  const { formState: { errors }, register, setValue, getValues, clearErrors, unregister } = methods;
  const configDefaultOption = {categoryType: "teamManagement", requestType: 'usersMovementApproval'};
  const actionTypeConfig = useRef(null);
  const [categoryType, setCategoryType] = useState('');
  const [disableRequestTypeSelect, setDisableRequestTypeSelect] = useState(false);
  const [requestType, setRequestType] = useState('');
  const [allowedRequests, setAllowedRequests] = useState([]);
  const [defaultRequest, setDefaultRequest] = useState({
    value: 'usersMovementApproval',
    label: (
      <div>
        <label>Choose a request</label>
        {/* <small className="text-muted">Create a request to move a user or team</small> */}
      </div>
    )
  });


  const setApiRequestObj = (data) => {
    console.log('INCOMING DATA:', data)
    apiRequestObj.current = data;

    if(apiRequestObj.current){
      onSubmit();
    }
  }
  const onChangeRequestType = async(requestType) => {
    setRequestType(requestType);
  }

  const onChangeCategoryType = (category) => {
    setCategoryType(category);
  }

  const handleCloseRequestor = () => {setOpenRequestor(dispatch, false); setRequestor(dispatch, {'clear': {}});};

  const approveRemoveRequest = async(requestId) => {
    const response = await TeamMembersProvider.approveEmployeeRemovalRequest({
      Id: requestId,
      ApproverComment: "Default Comment",
      ActionedBy: localStorage.getItem("EmployeeNumber")
    });

    if (response && response.status=='success') {
      successPrompt();
      localStorage.removeItem("teamMembers");

    }else{
      alert({
        icon:'error',
        title: 'Request created, instant approval failed.',
      });
    }
  }

  const successPrompt = () => {
    alert({
      icon:'success',
      title: 'Request Successfully Submitted.',
    });
    setRequestor(dispatch, {});
    apiRequestObj.current = null;
    unregister();
    clearErrors();
    setTimeout(handleCloseRequestor(), 2000);
  }
  const logRequestData = () => {
    console.log('apiRequestObj:', apiRequestObj)

  }

  const onSubmit = async () => {
    console.log('requestType:', requestType)
    console.log('apiRequestObj.current:', apiRequestObj.current)

    setLoader({show: true, message: 'Submitting Request'});
   
    if(requestType === 'usersMovementApproval'){
      if(apiRequestObj.current){
        const response = await ApprovalProvider.approveMovementRequest(apiRequestObj.current);
        if(response && response?.status=='success'){
          successPrompt();
          setTimeout(() =>  window.location.reload(), 2000);
          setLoader({show: false});
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    if(requestType === 'usersCreation'){
      if(apiRequestObj.current){
        const response = await ApprovalProvider.createAndApproveUserCreation(apiRequestObj.current);
        if(response && response?.status=='success'){
          successPrompt();
          setTimeout(() =>  window.location.reload(), 2000);
          setLoader({show: false});
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    if(requestType === 'userCampaignUpdate'){
      if(apiRequestObj.current){
        const response = await ApprovalProvider.createAndApproveUpdateCampaignsRequest(apiRequestObj.current);
        if(response && response?.status=='success'){
          successPrompt();
          setTimeout(() =>  window.location.reload(), 2000);
          setLoader({show: false});
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    if(requestType === 'userToRemove'){
      const response = await TeamMembersProvider.createBulkEmployeeRemovalRequest(apiRequestObj.current);
        if (response && response?.status=='success' && response?.data?.RequestId) {
          await approveRemoveRequest(response.data.RequestId);
          setLoader({show: false});

        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
    }
    if(requestType === 'campaignEscalation'){
      if(apiRequestObj.current){
        const response = await ApprovalProvider.approveEscalation({data: apiRequestObj.current});
        if(response && response?.status=='success'){
          successPrompt();
          setTimeout(() =>  window.location.reload(), 2000);
          setLoader({show: false});
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    if(requestType === 'managerEscalation'){
      if(apiRequestObj.current){
        const response = await ApprovalProvider.approveEscalation({data: apiRequestObj.current});
        if(response && response?.status=='success'){
          successPrompt();
          setTimeout(() =>  window.location.reload(), 2000);
          setLoader({show: false});
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    if(requestType === 'usersMovementRequest'){
      if(apiRequestObj.current){
        const response = await TeamMembersProvider.createUpdateCampaignsAndManagerRequest(apiRequestObj.current);
        if(response && response?.status=='success'){
          successPrompt();
          setLoader({show: false});
        }else{
          alert({
            icon:'error',
            title: 'Submission Failed.',
            text: response?.message || 'Something Went Wrong :('
          });
        }
      }
    }
    setLoader({show: false});
  };



 
  useEffect(async() => {
    let result = GetRequestTypeAccess({roleName: localStorage.getItem('roleName')});
    setAllowedRequests(result);
  }, [localStorage.getItem('roleName')])
  

  useEffect(() => {
    
    if(openRequestor){
      setDisableRequestTypeSelect(false);
      
      if(requestor && requestor.preloadData && Object.keys(requestor.preloadData).length > 0){
        const reqType = requestor.preloadData?.RequestType || null;
        switch (reqType) {
          case 'CAMPAIGN ESCALATION':
            setRequestType('campaignEscalation');
            setDisableRequestTypeSelect(true);
           
            
            break;

          case 'MANAGER ESCALATION':
            setRequestType('managerEscalation');
            setDisableRequestTypeSelect(true);
          
            break;

          case 'UPDATE CAMPAIGNS AND MANAGER':
            setRequestType('usersMovementApproval');
            setDisableRequestTypeSelect(true);
          
            break;
        
          default:
            // do nothing
            break;
        }

        return;
      }

        // setCategoryType(configDefaultOption.categoryType);
        // setRequestType(configDefaultOption.requestType);

    }else{
      console.log('HIT!')
      setRequestor(dispatch, {});
      apiRequestObj.current = null;
      unregister();
      clearErrors();
    }
  }, [openRequestor])

  useEffect(() => {
    if(openRequestor){
      unregister();
      clearErrors();
      setRequestor(dispatch, {'clear': {}});
    }
  }, [actionTypeConfig.current])

  const RenderView = () => {
    console.log('requestType:', requestType)
    switch (requestType) {
      case 'usersMovementApproval':
        return (
          <UsersMovement 
          preloadData={requestor?.preloadData}
          setApiRequestObj={setApiRequestObj}
          />
        )
        break;

      case 'usersCreation':
        return (
          <UsersCreation setApiRequestObj={setApiRequestObj} />
        )
        break;

      case 'userToRemove':
        return (
          <UserToRemove setApiRequestObj={setApiRequestObj} />
        )
        break;

      case 'campaignCreation':
        return (
          <CampaignCreation setApiRequestObj={setApiRequestObj} />
        )
        break;

      case 'userCampaignUpdate':
        return (
          <UserCampaignUpdate setApiRequestObj={setApiRequestObj} />
        )
        break;

      case 'campaignEscalation':
        return (
          <CampaignEscalation 
            preloadData={requestor?.preloadData}
            setApiRequestObj={setApiRequestObj}
          />
        )
        break;

      case 'managerEscalation':
        return (
          <CampaignEscalation 
            preloadData={requestor?.preloadData}
            setApiRequestObj={setApiRequestObj}
          />
        )
        break;

      case 'usersMovementRequest':
        return (
          <UsersMovementEscalation 
            preloadData={requestor?.preloadData}
            setApiRequestObj={setApiRequestObj}
          />
        )
        break;
    
      default:
        return (
          <div></div>
        )
        break;
    }

  }

  return (
    <>
    {openRequestor && loader && loader.show && <DefaultComponentLoader message={loader.message} />}
    <RequestorRoot modalactive={currentModal} className="requestor" variant="permanent" ownerState={{ openRequestor }}>
      {openRequestor &&
      <>
        {/* <FormProvider {...methods}> */}
          <div className="form">
            <SuiBox sx={{ maxWidth: 800,  minWidth: 800, margin: 'auto' }}>
              <SuiBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={3}
                pb={0.8}
                px={3}
              >
                <SuiBox>
                  <SuiTypography fontWeight="bold" variant="h5">Log a New Request</SuiTypography>
                  <SuiTypography variant="body2" color="text">
                    Welcome! You can log a request using options provided.
                  </SuiTypography>
                </SuiBox>

                <Icon
                  sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                    fontSize: `${size.md} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                    mt: 2,
                  })}
                  onClick={handleCloseRequestor}
                >
                  close
                </Icon>
              </SuiBox>

              <Divider />

              <SuiBox pt={1.25} pb={3} px={3}>
                
                {!disableRequestTypeSelect && 
                <>
                  <SuiBox mb={2} lineHeight={1}>
                    <SuiTypography variant="h6">Raise a request about</SuiTypography>
                    <SuiTypography variant="button" color="text" fontWeight="regular">
                      Choose type of request you want to log.
                    </SuiTypography>
                    <SuiBox mb={2} mt={1}>
                      <Select
                        isDisabled
                        classNamePrefix="custom-select"
                        onChange={(e) => {onChangeCategoryType(e.value)}}
                        className="select-control select-one"
                        value={{
                          value: 'teamManagement',
                          label: 'Team Management',
                        }}
                        options={[{
                          value: 'teamManagement',
                          label: 'Team Management',
                        }]}
                      />
                    </SuiBox>
                  </SuiBox>
                  <SuiBox mt={5} mb={2} lineHeight={1}>
                    <SuiTypography variant="h6">What can we help you with?</SuiTypography>
                    <SuiBox mb={2} mt={1}>
                      <Select
                        isSearchable={false}
                        classNamePrefix="custom-select"
                        className="select-control select-one select-with-html"
                        onChange={(e) => {onChangeRequestType(e.value)}}
                        defaultValue={defaultRequest}
                        isDisabled={disableRequestTypeSelect}
                        options={allowedRequests.map((request) => (
                          {
                            value: request.key,
                            label: (
                              <div>
                                <label>{request.title}</label>
                                <small className="text-muted">{request.description}</small>
                              </div>
                            )
                          }
                        ))}
                      />
                    </SuiBox>
                  </SuiBox>
                </>
                }

                {/* <RenderView/> */}
                {RenderView()}

              </SuiBox>
            </SuiBox>
          </div>
        {/* </FormProvider> */}
      </>
      }
    </RequestorRoot>
    </>
  );
}

export default Requestor;
