import React, { useEffect, useState } from "react";
import { HierarchyDiagram, HistoryMovementDiagram, UserHierarchyDiagram } from "../../components/Diagramview";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { setOpenRequestor, useSoftUIController } from "context";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import Footer from "examples/Footer";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import { isEmpty } from "lodash";
import { useForm } from 'react-hook-form';
import { IoMdClose } from "react-icons/io";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoMdRefreshCircle   } from "react-icons/io";
import BeatLoader from "react-spinners/BeatLoader";
import useAuth from "../../common/useAuth";
import Modal from '../../components/Modals/ModalSkeleton';
import UsersProvider from '../../providers/UsersProvider';
import ApprovalProvider from "../../providers/ApprovalProvider.js";


//UI Components
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import SuiAvatar from "components/SuiAvatar";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";

const TabPanel = (props) =>{
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box>{children}</Box>
        )}
        </div>
    );
}

const tabsFunction = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const teamMembers = (props) => {
    const { logout } = useAuth();
    const { handleSubmit, register } = useForm();
    const [controller, dispatch] = useSoftUIController();
    const [teamMembers, setTeamMembers] = useState({});
    const [fullTeamMembersList, setFullTeamMembersList] = useState([]);
    const [requestHistory, setRequestHistory] = useState({});
    const [userCampaigns, setUserCampaigns] = useState({});
    const [directSubordinates, setDirectSubordinates] = useState([]);
    const [roleId, setRoleId] = useState(localStorage.getItem("roleId"));
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(false);
    const [validUser, setValidUser] = useState(true);
    const [requestHistorySelected, setRequestHistorySelected] = useState(0);
    const [detailedTeamInfo, setDetailedTeamInfo] = useState({});
    const [movementHistoryInfo, setMovementHistoryInfo] = useState({});
    const [showDetailedTeamInformation, setShowDetailedTeamInformation] = useState(false);
    const [showMovementHistoryInformation, setShowMovementHistoryInformation] = useState(false);
    const [showUserHierarchy, setShowUserHierarchy] = useState(false);
    const [userSearchEmployeeNumber, setUserSearchEmployeeNumber] = useState('');
    const [value, setValue] = useState(0);
    const [detailedSectionTabValue, setDetailedSectionTabValue] = useState(0);
    const [movementHistorySectionTabValue, setMovementHistorySectionTabValue] = useState(0);
    const [filteredTeamList, setFilteredTeamList] = useState([]);
    const { openRequestor } = controller;
    const [myTeam, setMyTeam] = useState({});
    const [movementHistoryJson, setMovementHistoryJson] = useState({});
    const [userHierarchyJson, setUserHierarchyJson] = useState({});


    // return (
    //     <div>hi</div>
    // )

    const validateUser = async() => {
        try {
            const res = await ApprovalProvider.isValidApprovers([localStorage.getItem('EmployeeNumber') || '0000']);
            const token = localStorage.getItem("MSAuthToken") || null;

            if (!token) {
                alert({
                    icon:'error',
                    title: 'Failed MsAuth, You will be redirected to Login.',
                });
                return false;
            }

            if(res.status !== 'success' || (res.status === 'invalid' && res.data && res.data.length > 0)){
              return false;
            }

            return true;
            
          } catch (error) {
            console.log('error:', error);
            return false;
          }
    }

    //DataTable Defined Columns
    const teamMembersColumns = [
        { name: "Full Name", align: "left", selector: row => row.FullName, sortable: true },
        { name: "Employee Number", align: "left", selector: row => row.EmployeeNumber, sortable: true },
        { name: "Role", align: "left", selector: row => row.Role, sortable: true },
        { name: "Team", align: "left", selector: row => row.SubordinateUsers.length || '-', sortable: true },
        { name: "Campaigns", align: "left", selector: row => 
            ["Junior Manager", "General Manager"].includes(localStorage.getItem('roleName')) && row.Campaigns.length > 0 ?
             row.Campaigns.map((campaign, i) => i + 1 !== row.Campaigns.length ? campaign.Name + ', ' : campaign.Name) 
             : 
             ["Junior Manager", "General Manager"].includes(localStorage.getItem('roleName')) && row.SubsetCampaigns.length > 0 ?
             row.SubsetCampaigns[0].CampaignLeadSet
             : null, 
             sortable: true },
        // { name: "Date Added", align: "left", selector: row => moment(row.CreatedDate).format('DD/MM/YYYY'), sortable: true },
        { cell: () => <span style={{background: 'transparent', color: '#17C1E8', pointerEvents: 'none'}}>View More</span>, ignoreRowClick: false, expandableRows: true }
    ];

    const teamMemberCampaignsColumns = [
        {name: "Campaign Name", align: "left", selector: row => row.Name},
        {name: "Campaign Code", align: "left", selector: row => row.Code}
    ];

    const requestHistoryColumns = [
        { name: "Id", align: "left", selector: row => row.RequestId, sortable: true },
        { name: "Category", align: "left", selector: row => row.RequestCategory, sortable: true },
        { name: "Type", align: "left", selector: row => row.RequestType === 'UPDATE CAMPAIGNS AND MANAGER' ? 'MOVEMENT' : row.RequestType, sortable: true },
        { name: "Requester Employee Number", align: "left", selector: row => row.RequesterEmployeeNumber, sortable: true },
        { name: "Status", align: "left", selector: row => row.RequestStatus, sortable: true },
        { name: "Created Date", align: "left", selector: row => moment(row.CreatedDate).format('DD/MM/YYYY'), sortable: true },
        { cell: () => <span style={{background: 'transparent', color: '#17C1E8', pointerEvents: 'none'}}>View More</span>, ignoreRowClick: false, expandableRows: true }
    ];

    const fullTeamMembersListColumns = [
        {name: "FullName", align: "left", selector: row => row.FullName, sortable: true},
        {name: "Employee Number", align: "left", selector: row => row.EmployeeNumber, sortable: true},
        {name: "Role", align: "left", selector: row => row.Role, sortable: true},
        {name: "Reporting Manager", align: "left", selector: row => row.ManagerUsers.length > 0 ? row.ManagerUsers[0].FullName : "-", sortable: true}
    ];

    const movementHistoryColumns = [
        {name: "Manager FullName", align: "left", selector: row => row?.Manager.FullName, sortable: true},
        {name: "Manager Employee Number", align: "left", selector: row => row?.Manager.EmployeeNumber, sortable: true},
        {name: "Manager Role", align: "left", selector: row => row?.Manager.Role || '-', sortable: true},
        {name: "Date", align: "left", selector: row => row?.CreatedDate ? moment(row.CreatedDate).format('DD MMMM yyyy') : '-'}
    ];

    const buildTeam = (members) => {
        let membersList = [];
    
        for (let index = 0; index < members.length; index++) {
          const member = members[index];
          membersList.push(member);
      
          if (member.SubordinateUsers && member.SubordinateUsers.length > 0) {
            membersList = membersList.concat(buildTeam(member.SubordinateUsers));
            delete member.subordinates;
          }
        }
        return membersList;
      }
    
    const buildHierarchyObject = async() => {    
        //Recurvily get until no more suborinates
        const hierarchy = await buildTeamHierarchy(localStorage.getItem('userPrincipalName'));
        return hierarchy;
    }

    const buildTeamHierarchy = async(UserPrincipalName) => {
    const result = await getUsers(UserPrincipalName);
    const payloadObj = result[0];   

    for (let index = 0; index < payloadObj.SubordinateUsers.length; index++) {
        let subordinate = payloadObj.SubordinateUsers[index];
        if(subordinate && !isEmpty(subordinate.SubordinateUserIds)){
        payloadObj.SubordinateUsers[index] = await buildTeamHierarchy(subordinate.UserPrincipalName);
        }
    }

    return payloadObj;
    }

    const getUsers = async(userPrincipalName) => {
        try {
            const res = await UsersProvider.getUsersSearch({userPrincipalName});
            return res ? res : null;

        } catch (error) {
            
        }
    }

    useEffect(async() => {

        const getUserInfo = async () => {
            setLoading(true);
            let myTeam = null;
            // const role = roleId === 1 ? 1 : Math.min(roleId - 1, 3);

            try {
                if(localStorage.getItem("teamMembers")){
                    myTeam = JSON.parse(localStorage.getItem("teamMembers"))
                }
            } catch (error) {
                console.log('JSON Parsing team members failed...')
            }


            if(!myTeam){
                const fullTeam = await buildHierarchyObject();
                localStorage.setItem("teamMembers", JSON.stringify(fullTeam));
                myTeam = fullTeam;
            }

            /* CLEANUP NEEDS TO BE DONE FOR STATE VARAIBLES, SIMILIAR / DUPLICATE */
            setMyTeam(myTeam);
            if (!isEmpty(myTeam)) {
                let fullTeamMembersList = await buildTeam(myTeam.SubordinateUsers);
                setFullTeamMembersList(fullTeamMembersList);
                setFilteredTeamList(fullTeamMembersList);
                let subordinates = myTeam.SubordinateUsers;
                setTeamMembers({count: myTeam.SubordinateUsersCount, data: subordinates});
                setUserCampaigns({count: myTeam?.Campaigns?.length || 0, data: subordinates});
                setPending(false);
                if (myTeam.SubordinateUserIds) {
                    const directSubordinatesArray = myTeam.SubordinateUserIds.split(",");
                    setDirectSubordinates(directSubordinatesArray);
                }
            }
            setLoading(false);
        }
        const getUserReq = async () => {
            try {
                const userRequests = await UsersProvider.getUserRequests();

                if (userRequests?.status === 401) {
                    logout();
                    return;
                }

                if(userRequests && userRequests.length > 0){
                    setRequestHistory({count: userRequests.length, data: userRequests});
                }
            } catch (error) {
                console.log('error:', error)
            }

        }
        
        const valid = await validateUser();
        if(!valid){
            setValidUser(false);
        }
        else{
            getUserInfo();
            getUserReq();
        }
    }, []);


    const refetchHierarchyData = async() => {
        setLoading(true);
        let myTeam = null;
        // const role = roleId === 1 ? 1 : Math.min(roleId - 1, 3);

        const fullTeam = await buildHierarchyObject();
        localStorage.setItem("teamMembers", JSON.stringify(fullTeam));
        myTeam = fullTeam;
        setMyTeam(myTeam);
        
        if (!isEmpty(myTeam)) {
            let fullTeamMembersList = buildTeam(myTeam.SubordinateUsers);
            setFullTeamMembersList(fullTeamMembersList);
            setFilteredTeamList(fullTeamMembersList);
            let subordinates = myTeam.SubordinateUsers;
            setTeamMembers({count: myTeam.SubordinateUsersCount, data: subordinates});
            setUserCampaigns({count: myTeam?.Campaigns?.length || 0, data: subordinates});
            setPending(false);
            if (myTeam.SubordinateUserIds) {
                const directSubordinatesArray = myTeam.SubordinateUserIds.split(",");
                setDirectSubordinates(directSubordinatesArray);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if(requestHistorySelected !== 0){
          setDetailedTeamInfo([]);
        }
      }, [requestHistorySelected])



    const DetailedTeamInformation = () => {
        
        return (<>
            <Modal style={'fullscreen'}>
                <SuiBox mb={2} className="detailed-team-info-container" display="flex" flexDirection="column">
                    <SuiBox className="action-btns" display="flex" justifyContent="space-between" mb={4}>
                        <SuiTypography onClick={() => setShowDetailedTeamInformation(false)} display="flex" gap={1} alignItems="center" color="text" fontWeight="medium" variant="button"> <IoChevronBackCircleOutline /> Back</SuiTypography>
                        <IoMdClose onClick={() => setShowDetailedTeamInformation(false)} color="error" display="flex" gap={1} alignItems="center" variant="button"> <IoMdClose sx={({typography: {size, fontWeightBold}, pallet: {dark}}) => ({fontSize: `${size.md} !important`, fontWeight: `${fontWeightBold} !important`, stroke: dark.main, strokeWidth: "2px", cursor: "pointer", mt: 2})} /> Close</IoMdClose>
                    </SuiBox>
                    <SuiTypography style={{textAlign: 'center'}} fontWeight="bold" variant="h5">Detailed Team Information</SuiTypography>
                    <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Tabs className="tabs-container" value={detailedSectionTabValue} onChange={handleDetailedInfoChange} aria-label="basic tabs example">
                            <Tab className="tab-item" label="List View" {...tabsFunction(0)} />
                            <Tab className="tab-item" label="Diagram View" {...tabsFunction(1)} />
                        </Tabs>

                        {detailedSectionTabValue == 0 && 
                        <SuiBox mb={1} mt={4} className="filters" display="flex" alignItems="center">
                            <SuiTypography mx={2} SuiTypography fontWeight="bold" color="text" variant="button">Filter By:</SuiTypography>
                            <Dropdown className="role-filter" onSelect={(Role) => filterByRole(Role)}>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    Role
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="agent">Agent</Dropdown.Item>
                                    <Dropdown.Item eventKey="junior manager">Junior Manager</Dropdown.Item>
                                    <Dropdown.Item eventKey="general manager">General Manager</Dropdown.Item>
                                    <Dropdown.Item eventKey="head of sales">Head of Sales</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                           
                            <SuiButton className="clear-filter-btn" onClick={() => {setFilteredTeamList(fullTeamMembersList)}} variant="text" color="info">
                                Clear Filter
                            </SuiButton>
                        </SuiBox>}
                    </SuiBox>
                    <TabPanel style={{overflowY: 'auto'}} value={detailedSectionTabValue} index={0}>
                        <SuiBox py={1}>
                        { fullTeamMembersList && <DataTable
                            columns={fullTeamMembersListColumns} 
                            data={filteredTeamList}
                            fixedHeader
                            pagination
                            hover="true" 
                            striped="true"
                            highlightOnHover
                            pointerOnHover
                        /> }
                        </SuiBox>
                    </TabPanel>
                    <TabPanel style={{overflowY: 'auto'}} value={detailedSectionTabValue} index={1}>
                        <SuiBox py={1}>
                        {!isEmpty(myTeam) && loading ? <BeatLoader color={'#D0021B'} loading={loading} size={15} />  : !isEmpty(myTeam) && <HierarchyDiagram myTeam={myTeam} setLoading={setLoading} />}
                        </SuiBox>
                    </TabPanel>
                </SuiBox>
            </Modal>
            </>)
    }

    const MovementHistoryInformation = ({user}) => {
        
        return (
            <Modal style={'fullscreen'}>
                <SuiBox mb={2} className="detailed-team-info-container" display="flex" flexDirection="column">
                    <SuiBox className="action-btns" display="flex" justifyContent="space-between" mb={4}>
                        <SuiTypography onClick={() => setShowMovementHistoryInformation(false)} display="flex" gap={1} alignItems="center" color="text" fontWeight="medium" variant="button"> <IoChevronBackCircleOutline /> Back</SuiTypography>
                        <IoMdClose onClick={() => setShowMovementHistoryInformation(false)} color="error" display="flex" gap={1} alignItems="center" variant="button"> <IoMdClose sx={({typography: {size, fontWeightBold}, pallet: {dark}}) => ({fontSize: `${size.md} !important`, fontWeight: `${fontWeightBold} !important`, stroke: dark.main, strokeWidth: "2px", cursor: "pointer", mt: 2})} /> Close</IoMdClose>
                    </SuiBox>
                    <SuiTypography style={{textAlign: 'center'}} fontWeight="bold" variant="h5">User Movement History</SuiTypography>
                    <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Tabs className="tabs-container" value={movementHistorySectionTabValue} onChange={handleMovementHistoryInfoChange} aria-label="basic tabs example">
                            <Tab className="tab-item" label="List View" {...tabsFunction(0)} />
                            <Tab className="tab-item" label="Diagram View" {...tabsFunction(1)} />
                        </Tabs>
                    </SuiBox>
                    <TabPanel style={{overflowY: 'auto'}} value={movementHistorySectionTabValue} index={0}>
                        <SuiBox py={1}>
                        { movementHistoryInfo && <DataTable
                            columns={movementHistoryColumns} 
                            data={movementHistoryInfo}
                            fixedHeader
                            pagination
                            hover="true" 
                            striped="true"
                            highlightOnHover
                            pointerOnHover
                        /> }
                        </SuiBox>
                    </TabPanel>
                    <TabPanel style={{overflowY: 'auto'}} value={movementHistorySectionTabValue} index={1}>
                        <SuiBox py={1}>
                           <HistoryMovementDiagram movementHistoryInfo={movementHistoryJson}/>
                        </SuiBox>
                    </TabPanel>
                </SuiBox>
            </Modal>
        )
    }

    const UserHierarchyInformation = ({user}) => {
        
        return (
            <Modal style={'fullscreen'}>
                <SuiBox mb={2} className="detailed-team-info-container" display="flex" flexDirection="column">
                    <SuiBox className="action-btns" display="flex" justifyContent="space-between" mb={4}>
                        <SuiTypography onClick={() => setShowUserHierarchy(false)} display="flex" gap={1} alignItems="center" color="text" fontWeight="medium" variant="button"> <IoChevronBackCircleOutline /> Back</SuiTypography>
                        <IoMdClose onClick={() => setShowUserHierarchy(false)} color="error" display="flex" gap={1} alignItems="center" variant="button"> <IoMdClose sx={({typography: {size, fontWeightBold}, pallet: {dark}}) => ({fontSize: `${size.md} !important`, fontWeight: `${fontWeightBold} !important`, stroke: dark.main, strokeWidth: "2px", cursor: "pointer", mt: 2})} /> Close</IoMdClose>
                    </SuiBox>
                    <SuiTypography style={{textAlign: 'center'}} fontWeight="bold" variant="h5">User Hierarchy</SuiTypography>
                    <SuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Tabs className="tabs-container" value={movementHistorySectionTabValue} onChange={handleMovementHistoryInfoChange} aria-label="basic tabs example">
                            <Tab className="tab-item" label="Diagram View" {...tabsFunction(0)} />
                        </Tabs>
                    </SuiBox>
                    <TabPanel style={{overflowY: 'auto'}} value={movementHistorySectionTabValue} index={0}>
                        <SuiBox py={1}>
                           <UserHierarchyDiagram userHierarchyInfo={userHierarchyJson}/>
                        </SuiBox>
                    </TabPanel>
                </SuiBox>
            </Modal>
        )
    }

    const TeamMemberExpandedComp = ({ data }) => <div className="expanded-details p-5">
        <SuiBox key={data.Id} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <SuiBox className="avatar" mr={2}>
        <SuiAvatar fullName={data.FullName} style={{width: '4rem', height: '4rem'}} src={''} alt="profile picture" variant="rounded" shadow="md"/>
      </SuiBox>
      <SuiBox className="avatar-profile-details" display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <SuiTypography fontWeight="bold" variant="button">
          {data.FullName}
        </SuiTypography>
        <SuiTypography fontWeight="medium" variant="button">
            {data.Role}
        </SuiTypography>
        <SuiTypography fontWeight="medium" variant="button">
            {data.EmployeeNumber} / {data.UserName}
        </SuiTypography>
      </SuiBox>
    </SuiBox>

    {/* <hr/> */}
    <SuiBox my={4} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <SuiTypography variant="button" className="mb-4">Profile Information:</SuiTypography>
        <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
            <SuiTypography fontWeight="bold" variant="button" >Date Added: </SuiTypography>{moment(data.CreatedDate).format('DD MMMM yyyy')}
        </SuiTypography>
        <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
            <SuiTypography fontWeight="bold" variant="button">Mobile Number: </SuiTypography>{(data.MobileNumber)}
        </SuiTypography>
        <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
            <SuiTypography fontWeight="bold" variant="button">Email: </SuiTypography>{(data.UserPrincipalName)}
        </SuiTypography>
        {data?.SubsetCampaigns && data?.SubsetCampaigns.length > 0 && <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
            <SuiTypography fontWeight="bold" variant="button">Campaign Assigned: </SuiTypography>{(data.SubsetCampaigns[0].CampaignLeadSet || null)}
        </SuiTypography>}
    </SuiBox>

    {data && data.Campaigns.length > 0 &&
        <DataTable
            columns={teamMemberCampaignsColumns} 
            data={data.Campaigns}
            fixedHeader
            pagination
            progressPending={pending}
            progressComponent={<BeatLoader color={'#D0021B'} size={15} margin={2} />}
            hover="true" 
            striped="true"
            highlightOnHover
            pointerOnHover
        />
    }
    <hr/>
    </div>;

    const RequestHistoryExpandedComp = ({data}) => {
        return (
            <div key={data.RequestId} className="expanded-details p-5" >
                <SuiBox my={4} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                    <SuiButton variant="text" color="info" onClick={() => isEmpty(detailedTeamInfo) ? getRequestHistory2(data.RequestId) : setDetailedTeamInfo([])}>
                        {detailedTeamInfo && !isEmpty(detailedTeamInfo) ? 'VIEW LESS INFO' : 'GET MORE INFO'}
                    </SuiButton>
                    {detailedTeamInfo && !isEmpty(detailedTeamInfo) && (
                        <SuiBox mt={3} ml={1} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                            <SuiTypography fontWeight="medium" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button">ID: </SuiTypography>{(detailedTeamInfo.RequestId)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button">Type: </SuiTypography>{(detailedTeamInfo.RequestType === 'UPDATE CAMPAIGNS AND MANAGER' ? 'MOVEMENT' : detailedTeamInfo.RequestType)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button">Category: </SuiTypography>{(detailedTeamInfo.RequestCategory)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button">Status: </SuiTypography>{(detailedTeamInfo.RequestStatus)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button">Movement Reason: </SuiTypography>{(detailedTeamInfo.UserHierarchyMovementReason)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button">Effective Date: </SuiTypography>{(detailedTeamInfo.EffectiveDate)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button"> Requestor Name: </SuiTypography>{(detailedTeamInfo.Requestor.FullName)}
                            </SuiTypography>
                            <SuiTypography fontWeight="regular" className="profile-info-text mb-2" variant="button">
                                <SuiTypography fontWeight="bold" variant="button"> Requestor Employee Number: </SuiTypography>{(detailedTeamInfo.Requestor.EmployeeNumber)}
                            </SuiTypography>
                            <SuiBox mt={2} display="flex" flexDirection="column">
                                <SuiTypography fontWeight="bold" variant="button">Approvers:</SuiTypography>
                                {detailedTeamInfo.Approvers && detailedTeamInfo.Approvers.map((approvers) => (
                                    <SuiBox display="flex" key={approvers.Code} gap={1}>
                                        <SuiTypography variant="button" fontWeight="regular" className="profile-info-text mb-2">{(approvers.FullName)}</SuiTypography>
                                        <SuiTypography variant="button" fontWeight="bold" className="profile-info-text mb-2">( {(approvers.EmployeeNumber)} )</SuiTypography>
                                    </SuiBox>
                                ))}
                            </SuiBox>
                            <SuiBox mt={2}>
                                <SuiTypography fontWeight="bold" variant="button">Campaigns:</SuiTypography>
                                {detailedTeamInfo.Campaigns !== undefined && detailedTeamInfo.Campaigns.map((campaign) => (
                                    <SuiBox display="flex" key={campaign.Code} gap={1}>
                                        <SuiTypography variant="button" fontWeight="regular" className="profile-info-text mb-2">{(campaign.Name)}</SuiTypography>
                                        <SuiTypography variant="button" fontWeight="bold" className="profile-info-text mb-2">( {(campaign.Code)} )</SuiTypography>
                                    </SuiBox>
                                ))}
                                {detailedTeamInfo.Users[0].Campaigns !== undefined && detailedTeamInfo.Users[0].Campaigns.map((campaign) => (
                                    <SuiBox display="flex" key={campaign.Code} gap={1}>
                                        <SuiTypography variant="button" fontWeight="regular" className="profile-info-text mb-2">{(campaign.Name)}</SuiTypography>
                                        <SuiTypography variant="button" fontWeight="bold" className="profile-info-text mb-2">( {(campaign.Code)} )</SuiTypography>
                                    </SuiBox>
                                ))}
                            </SuiBox>
                            <SuiBox mt={2}>
                                <SuiTypography fontWeight="bold" variant="button">Users:</SuiTypography>
                                {detailedTeamInfo.Users && detailedTeamInfo.Users.map((users) => (
                                    <SuiBox display="flex" key={users.Code} gap={1}>
                                        <SuiTypography variant="button" fontWeight="regular" className="profile-info-text mb-2">{(users.FullName)}</SuiTypography>
                                        <SuiTypography variant="button" fontWeight="bold" className="profile-info-text mb-2">( {(users.EmployeeNumber)} )</SuiTypography>
                                    </SuiBox>
                                ))}
                            </SuiBox>
                        </SuiBox>
                    )}
                </SuiBox>
            </div>
        )
    } 
    
    const GetFullNameWithRole = (person) => {
        switch (person.Role) {
            case "Head of Department":
                return `${person.FullName} (HOD)`;
            case "General Manager":
                return `${person.FullName} (GM)`;
            case "Junior Manager":
                return `${person.FullName} (JM)`;
            case "Agent":
                return `${person.FullName} (Agent)`;
            default:
                return person.FullName;
        }
    };

    const ComposeHistoryObject = (i, key, currentObject, additionalSubordinateUsers) => {
        i === key ? currentObject.SubordinateUsers = additionalSubordinateUsers :
        currentObject = currentObject.SubordinateUsers[0];

    };
    
    const ComposeHistoryJson = (movementHistory) => {
        let historyData = {};
        let additionalSubordinateUsers = [];

        if (movementHistory.length > 0) {
          historyData = {
            "Id": movementHistory[0].Id,
            "date":movementHistory[0].CreatedDate,
            "FullName": GetFullNameWithRole(movementHistory[0]['Manager']),
            "EmployeeNumber": movementHistory[0]['Manager'].EmployeeNumber,
            "Role": movementHistory[0]['Manager'].Role,
            SubordinateUsers: []
          };
      
          movementHistory.forEach((item, key) => {

            (key + 1) < movementHistory.length && additionalSubordinateUsers.push({
              "Id": item.Id,
              "date" : item.CreatedDate,
              "FullName": GetFullNameWithRole(item['Manager']),
              "EmployeeNumber": item['Manager'].EmployeeNumber,
              "Role": item['Manager'].Role,
              SubordinateUsers: []
            });
           
            let currentObject = historyData;
            for (let i = 1; i <= key; i++) {
               if ((i + 1) == movementHistory.length){
                    additionalSubordinateUsers.push({
                        "Id": item.Id,
                        "date" :item.CreatedDate,
                        "FullName": GetFullNameWithRole(item['Manager']),
                        "EmployeeNumber": item['Manager'].EmployeeNumber,
                        "Role": item['Manager'].Role,
                        SubordinateUsers: [{
                            "Id": item.Id,
                            "date" :item.CreatedDate,
                            "FullName": GetFullNameWithRole(item['User']),
                            "EmployeeNumber": item['Manager'].EmployeeNumber,
                            "Role": item['User'].Role,
                            SubordinateUsers: []
                          }]
                      })
                      ComposeHistoryObject(i, key, currentObject, additionalSubordinateUsers);
                }
                (i + 1) < movementHistory.length &&
                    i === key ? currentObject.SubordinateUsers = additionalSubordinateUsers :
                    currentObject = currentObject.SubordinateUsers[0];
            }
            additionalSubordinateUsers = [];
          });
        }
        return historyData;
    };

    const getRequestHistory2 = async (RequestId) => {
        const data2= await ApprovalProvider.getRequestInformation(RequestId);
        setDetailedTeamInfo(data2)
    }

    const getUserHiearchy = async() => {
        setLoading(true);
        // let jsonArray= [];
        const hierarchy = await UsersProvider.getUserHierarchyByEmployeeNumber({employeeNumber: userSearchEmployeeNumber});
        
        if(hierarchy){
            setUserHierarchyJson(hierarchy);
            setShowUserHierarchy(true);
        }

        setLoading(false);
    }

    const getMovementHistory = async() => {
        setLoading(true);
        // let jsonArray= [];
        const movementHistory = await UsersProvider.getUserMovementHistory({employeeNumber: userSearchEmployeeNumber});
        
        if(movementHistory && movementHistory.length > 0){
            setMovementHistoryInfo(movementHistory);

            // Was going to be used for optimization of the Movement History Obj
            // jsonArray = movementHistory.map((item) => ({
            //     FullName: item.Manager.FullName,
            //     Id: item.Manager.Id,
            //     EmployeeNumber: item.Manager.EmployeeNumber,
            //     Role: item.Manager.Role,
            //     Date: item.InsertedDate,
            //     Suborinate: {
            //         FullName: item.User.FullName,
            //         EmployeeNumber: item.User.EmployeeNumber,
            //         Role: item.User.Role
            //     }
            // }))
            setShowMovementHistoryInformation(true);
        }


        setMovementHistoryJson(ComposeHistoryJson(movementHistory))
        setLoading(false);
    }

    const handleRequestorOpen = () => setOpenRequestor(dispatch, !openRequestor);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDetailedInfoChange = (event, newValue) => {
        setDetailedSectionTabValue(newValue);
    };

    const handleMovementHistoryInfoChange = (event, newValue) => {
        setMovementHistorySectionTabValue(newValue);
    };

    const filterByRole = (Role) => {
        let filteredTeamList = fullTeamMembersList.filter((member) => member.Role.toLowerCase() === Role);
        setFilteredTeamList(filteredTeamList);
    }



    const onUserSearchSubmit = () => {

    }

    if(!validUser)
    return (
        <div></div>
    )

    return (
        <div className="team-management-page">
            <DashboardLayout>
                {/* <DashboardNavbar /> */}
                <SuiBox display="flex" justifyContent="space-between" py={2} pr={1}>
                    <SuiBox gap="20px" display="flex" alignItems="baseline" py={2} pr={1}>
                        <SuiButton onClick={() => refetchHierarchyData()} size="small" fontWeight="thin" variant="contained" color="info" disabled={loading} type="submit" >
                            <IoMdRefreshCircle /> &nbsp; Refresh Team
                        </SuiButton>
                        {loading && <BeatLoader color={'#D0021B'} size={7} margin={2} />}
                    </SuiBox>
                    <IconButton size="small" onClick={() => logout()}>
                    <SuiTypography
                    variant="button"
                    fontWeight="medium"
                    color={"dark"}
                    >
                    Sign out
                    </SuiTypography>
                    </IconButton>
                </SuiBox>
                {showDetailedTeamInformation && <DetailedTeamInformation />}
                {showMovementHistoryInformation && <MovementHistoryInformation />}
                {showUserHierarchy && <UserHierarchyInformation />}

                <SuiBox mb={3} mt={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} xl={3}>
                            <MiniStatisticsCard
                                title={{ text: "my team" }}
                                count={teamMembers.count || 0}
                                icon={{ color: "info", component: "group" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <MiniStatisticsCard
                                title={{ text: "Campaigns" }}
                                count={userCampaigns.count || 0}
                                icon={{ color: "info", component: "campaigns" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                            <MiniStatisticsCard
                            title={{ text: roleId === '4' ? "General Managers" : roleId === '3' ? "Junior Managers" : "Agents" }}
                            count={directSubordinates.length || 0}
                            icon={{ color: "info", component: "manager" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                        <a onClick={handleRequestorOpen}>
                            <PlaceholderCard
                            p={2}
                            outlined
                            className="new-request_placeholder-card"
                            iconStyle={{ height: 22, mb: 0 }}
                            title={{
                                variant: "h6",
                                text: "New Request",
                            }}
                            />
                        </a>
                        </Grid>
                    </Grid>
                </SuiBox>
                    <Card>
                        <Grid className="team-members-grid">
                            <SuiBox display="flex" justifyContent="space-between" px={5} py={3} alignItems="baseline">
                                <SuiBox display="flex">
                                    <SuiTypography variant="h6">{value == 0 ? 'Team Members - ' : value === 1 ? 'Request History' : 'User Search'}</SuiTypography>
                                    {value == 0 && <SuiButton disabled={loading || isEmpty(myTeam)} onClick={() => {setShowDetailedTeamInformation(true)}} variant="text" color="info">
                                        View Detailed Team Information
                                    </SuiButton>}
                                </SuiBox>
                                <Tabs className="tabs-container" value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab className="tab-item" label="Team Members" {...tabsFunction(0)} />
                                    <Tab className="tab-item" label="Request History" {...tabsFunction(1)} />
                                    <Tab className="tab-item" label="User Search" {...tabsFunction(2)} />
                                </Tabs>
                            </SuiBox>
                            <TabPanel value={value} index={0}>
                                <SuiBox py={1}>
                                    <DataTable 
                                        columns={teamMembersColumns} 
                                        data={teamMembers.data}
                                        expandableRows
                                        fixedHeader
                                        pagination
                                        progressPending={pending}
                                        progressComponent={<BeatLoader color={'#D0021B'} size={15} margin={2} />}
                                        hover="true" 
                                        striped="true"
                                        expandableRowsComponent={TeamMemberExpandedComp}
                                        expandOnRowClicked
                                        highlightOnHover
                                        pointerOnHover
                                    />
                                </SuiBox>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <SuiBox mb={3}>
                                    <DataTable 
                                        columns={requestHistoryColumns} 
                                        data={requestHistory.data}
                                        expandableRows
                                        fixedHeader
                                        pagination
                                        progressPending={pending}
                                        progressComponent={<BeatLoader color={'#D0021B'} size={15} margin={2} />}
                                        hover="true" 
                                        striped="true"
                                        expandableRowsComponent={RequestHistoryExpandedComp}
                                        onRowClicked={(row) => {row.RequestId !== requestHistorySelected ? setRequestHistorySelected(row.RequestId) : setRequestHistorySelected(0)}}
                                        expandableRowExpanded={(row)=> {return row.RequestId === requestHistorySelected ?  true :  false}}
                                        highlightOnHover
                                        pointerOnHover
                                    />
                                </SuiBox>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div>
                                    <form form="movement-history-form" onSubmit={handleSubmit(getMovementHistory)}>
                                        <SuiBox p={5} mb={3} display="flex" flexDirection="column" justifyContent="center">
                                            <Grid container flexDirection="column" justifyContent="center" alignItems="left" gap={4}>
                                                <Grid item>
                                                    <SuiBox>
                                                        <SuiInput {...register("mh-employeeNumber", {required: true, pattern: /^[0-9]+$/})} title="Please enter a numeric value" id="employeeNumber" disabled={loading} onChange={(e) => setUserSearchEmployeeNumber(e.target.value)} type="text" placeholder="Eg: 1234" />
                                                    </SuiBox>
                                                </Grid>
                                                <Grid alignItems="center" display="flex" gap={2} item>
                                                    <SuiBox position="relative" >
                                                        <SuiButton onClick={() => getUserHiearchy()} disabled={true} color="info" type="submit" >
                                                            Retrieve Hierarchy
                                                        </SuiButton>
                                                        <div style={{position:'absolute', bottom:'-30px', left:'30%', width: '200px'}}><SuiTypography variant="overline">Coming Soon...</SuiTypography></div>
                                                    </SuiBox>
                                                    <SuiTypography variant="overline">OR</SuiTypography>
                                                    <SuiBox position="relative" >
                                                        <SuiButton onClick={() => getMovementHistory()} disabled={true} color="info" type="submit" >
                                                            Retrieve Movement History
                                                        </SuiButton>
                                                        <div style={{position:'absolute', bottom:'-30px', left:'30%', width: '200px'}}><SuiTypography variant="overline">Coming Soon...</SuiTypography></div>
                                                    </SuiBox>
                                                </Grid>
                                                <Grid display="flex" justifyContent="center" item xs={12}>
                                                    <SuiBox >
                                                        {loading && <BeatLoader color={'#D0021B'} loading={loading} size={15} />}
                                                    </SuiBox>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </form>
                                </div>
                            </TabPanel>
                        </Grid>
                    </Card>
                    <br />
                <Footer />
            </DashboardLayout>
        </div>
    );
}

export default teamMembers;